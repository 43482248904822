export enum Timezone {
  "[GMT-10:00] Hawaiian Time" = "Pacific/Honolulu", // UTC-10:00
  "[GMT-09:00] Alaskan Time" = "America/Anchorage", // UTC-09:00
  "[GMT-08:00] Pacific Time" = "America/Los_Angeles", // UTC-08:00
  "[GMT-07:00] Mountain Time" = "America/Denver", // UTC-07:00
  "[GMT-06:00] Central Time" = "America/Chicago", // UTC-06:00
  "[GMT-05:00] Eastern Time" = "America/New_York", // UTC-05:00
  "[GMT-04:00] Atlantic Time" = "America/Halifax", // UTC-04:00
  "[GMT+00:00] Greenwich Mean Time" = "GMT", // UTC+00:00
  "[GMT+01:00] Central European Time" = "Europe/Paris", // UTC+01:00
  "[GMT+02:00] Eastern European Time" = "Europe/Bucharest", // UTC+02:00
  "[GMT+03:00] East Africa Time" = "Africa/Nairobi", // UTC+03:00
  "[GMT+03:00] Moscow Standard Time" = "Europe/Moscow", // UTC+03:00
  "[GMT+04:00] Arabian Standard Time" = "Asia/Dubai", // UTC+04:00
  "[GMT+05:00] Pakistan Standard Time" = "Asia/Karachi", // UTC+05:00
  "[GMT+05:30] Indian Standard Time" = "Asia/Kolkata", // UTC+05:30
  "[GMT+06:00] Bangladesh Standard Time" = "Asia/Dhaka", // UTC+06:00
  "[GMT+07:00] Indochina Time" = "Asia/Bangkok", // UTC+07:00
  "[GMT+08:00] China Standard Time" = "Asia/Shanghai", // UTC+08:00
  "[GMT+09:00] Korea Standard Time" = "Asia/Seoul", // UTC+09:00
  "[GMT+09:30] Australian Central Standard Time" = "Australia/Darwin", // UTC+09:30
  "[GMT+10:00] Australian Eastern Standard Time" = "Australia/Sydney", // UTC+10:00
  "[GMT+11:00] Pacific/Fiji" = "Pacific/Fiji", // UTC+11:00
  "[GMT+12:00] New Zealand Standard Time" = "Pacific/Auckland", // UTC+12:00
}
