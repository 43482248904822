import { useIsAuthenticated } from "@azure/msal-react";
import { getSubscriptionsPublicByRoleGuidAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useSubscriptionsPublicQuery = (roleGuid: string) => {
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["subscriptionsPublic", roleGuid];

  const query = useQuery({
    enabled: isAuthenticated,
    queryFn: () => {
      if (!roleGuid) throw new Error("Role guid is required");
      return getSubscriptionsPublicByRoleGuidAsync(roleGuid);
    },
    queryKey: queryKey,
  });

  return {
    subscriptionList: query.data,

    isSubscriptionListLoading: query.isLoading,
    isSubscriptionListError: query.isError,
  };
};
