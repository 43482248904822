import {
  BaseSocialLinkDto_v1,
  InsertSocialLinkApiResponse_v1,
  InsertSocialLinkRequest_v1,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const insertSocialLinkAsync = async (
  profileGuid: string,
  socialLink: BaseSocialLinkDto_v1
) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig<InsertSocialLinkRequest_v1> = {
    data: {
      profileGuid: profileGuid,
      socialLink: socialLink,
    },
    method: "POST",
    url: `/api/v1/socialLink`,
  };

  return client
    .request<InsertSocialLinkApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertSocialLinkAsync error.", data.errors);
        throw new Error("Error adding socialLink.");
      }

      // Return payload.
      return data.payload.socialLinkGuid;
    });
};
