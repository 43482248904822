import { useIsAuthenticated } from "@azure/msal-react";
import { getSubscriptionsByProfileGuidAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useProfileSubscriptionsQuery = (profileGuid?: string) => {
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["profileSubscriptions", profileGuid];

  console.log(profileGuid);

  const query = useQuery({
    queryKey: queryKey,
    enabled: isAuthenticated,
    queryFn: () => {
      if (!profileGuid) throw new Error("Profile guid is required");
      return getSubscriptionsByProfileGuidAsync(profileGuid);
    },
  });

  return {
    profileSubscriptions: query.data || [],

    isProfileSubscriptionsLoading: query.isLoading,
    isProfileSubscriptionsError: query.isError,
  };
};
