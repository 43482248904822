import { AppConfiguration } from "./appConfiguration";
import { config } from "./configs/config.development";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let global: any;
declare global {
  interface Window {
    config: AppConfiguration;
  }
}

export const getConfig = () => {
  ("undefined" != typeof global ? global : window).config = {
    ...config,
    ...process.env,
    ...window.config,
  };
  return ("undefined" != typeof global ? global : window)
    .config as AppConfiguration;
};
