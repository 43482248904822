import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteProfileAsync,
  getProfileByGuidAsync,
  insertProfileAsync,
  updateProfileAsync,
} from "@bu/lib-services";
import { BaseProfileDto_v1, ProfileDto_v1, RoleDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

/**
 * Queries a profile by profileGuid.
 * @param profileGuid (Optional) The profile guid to query. If not provided, the profile will be a baseProfile and must be inserted first.
 */
export const useProfileQuery = (profileGuid?: string) => {
  const [_profileGuid, _setProfileGuid] = useState(profileGuid);
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["profile", _profileGuid];
  const queryClient = useQueryClient();

  // Updates internal GUID state if the GUID changes.
  useEffect(() => {
    if (_profileGuid === profileGuid) return;
    _setProfileGuid(profileGuid);
  }, [profileGuid, _profileGuid]);

  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!_profileGuid) throw new Error("BaseProfile must be inserted first.");
      return getProfileByGuidAsync(_profileGuid);
    },
    enabled: isAuthenticated,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      userGuid: string;
      roleGuid: string;
      subscriptionGuid: string;
      baseProfile: BaseProfileDto_v1;
    }) => {
      if (_profileGuid) throw new Error("Profile cannot be inserted again.");
      return insertProfileAsync(
        mutationArgs.userGuid,
        mutationArgs.roleGuid,
        mutationArgs.subscriptionGuid,
        mutationArgs.baseProfile
      );
    },
    onSuccess: (newProfileGuid) => {
      _setProfileGuid(newProfileGuid);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (profile: ProfileDto_v1) => {
      if (!_profileGuid) throw new Error("BaseProfile must be inserted first.");
      return updateProfileAsync(profile);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      profile: ProfileDto_v1;
      role: RoleDto_v1;
    }) => {
      if (!_profileGuid) throw new Error("BaseProfile must be inserted first.");
      return deleteProfileAsync(mutationArgs.profile, mutationArgs.role);
    },
    onSuccess: () => {
      _setProfileGuid(undefined);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    profile: query.data,

    insertProfileAsync: insertMutation.mutateAsync,
    updateProfileAsync: updateMutation.mutateAsync,
    deleteProfileAsync: deleteMutation.mutateAsync,

    isProfileLoading: query.isLoading,
    isProfileError: query.isError,
  };
};
