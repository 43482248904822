import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteUserAsync,
  getUserByAzureGuidAsync,
  insertUserAsync,
  updateUserAsync,
} from "@bu/lib-services";
import { UserDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useSelfQuery = () => {
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();
  const queryKey = ["self"];

  const query = useQuery({
    queryKey: queryKey,
    enabled: isAuthenticated,
    queryFn: getUserByAzureGuidAsync,
  });

  const updateQuery = useMutation({
    mutationFn: (userData: UserDto_v1) => {
      return updateUserAsync(userData);
    },
    onSuccess: async (_, updatedSelf) => {
      const currentSelf = queryClient.getQueryData<UserDto_v1>(queryKey);
      queryClient.setQueryData(queryKey, { ...currentSelf, ...updatedSelf });
    },
  });

  const deleteQuery = useMutation({
    mutationFn: deleteUserAsync,
  });

  const insertQuery = useMutation({
    mutationKey: queryKey,
    mutationFn: insertUserAsync,
    onSuccess: async (newUserGuid, mutationArgs) => {
      queryClient.setQueryData(queryKey, {
        ...mutationArgs,
        guid: newUserGuid,
      });
      await query.refetch();
    },
  });

  return {
    self: query.data,

    insertSelfAsync: insertQuery.mutateAsync,
    updateSelfAsync: updateQuery.mutateAsync,
    deleteSelfAsync: deleteQuery.mutateAsync,

    isSelfLoading: query.isLoading,
    isSelfError: query.isError,
  };
};
