import { getSlidesPaginatedAsync } from "@bu/lib-services";
import { getSlidesAsyncProps } from "@bu/lib-types";
import { useQuery } from "@tanstack/react-query";

type UseMobileSlidesQueryPaginatedProps = getSlidesAsyncProps & {
  pageNo: number;
  slideGuid: string;
};

export const useMobileSlidesQueryPaginated = (
  props: UseMobileSlidesQueryPaginatedProps
) => {
  const query = useQuery({
    queryKey: ["getSlidesInfiniteQuery", props.contentGuid, props.pageNo],
    queryFn: () => {
      return getSlidesPaginatedAsync({
        content: props.content,
        contentGuid: props.contentGuid,
        pageNo: props.pageNo,
        slideGuid: props.slideGuid,
      });
    },
    enabled: false,
  });

  return {
    trigger: query.refetch,
  };
};

