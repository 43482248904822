import { DeleteSocialLinkApiResponse_v1 } from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteSocialLinkAsync = async (socialLinkGuid: string) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/socialLink/${socialLinkGuid}`,
  };

  return client
    .request<DeleteSocialLinkApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("DeleteSocialLinkAsync error.", data.errors);
        throw new Error("Error deleting socialLink.");
      }

      // Return payload.
      return data.payload;
    });
};
