import { getMobileTrackListAsyn } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useMobileTrackListQuery = (guids: string[]) => {
  const query = useQuery({
    queryKey: ["getTracksWithFilters", guids],
    queryFn: () => getMobileTrackListAsyn(guids),
    enabled: guids.length > 0,
  });

  return {
    tracks: query.data?.payload.tracks,
    isLoadingTracks: query.isLoading,
    refetchTracks: query.refetch,
  };
};

