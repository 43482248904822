import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const GetProfileAliasAvailabilityAsync = async (alias: string) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/api/v1/profile/alias/availability/{alias}",
  };

  return Promise.resolve(Math.random() > 0.5);
};
